<script >

import BaseButton from "../../../components/reuseables/BaseButton.vue";
import {addNewAdvert, getAllAdverts, updateAdvertStatus, uploadToCloudinary} from "../../../services/api/APIService";

export default {
  name: "AdvertDashboard",
  components: {BaseButton},
  data(){
    return{
      headers: [
        { text: "", value: "sno" },
        { text: "", value: "advertiser"},
        { text: "", value: "trackingId"},
        { text: "", value: "title" },
        { text: "", value: "amountPaid" },
        {text: "", value: "content" },
        { text: "", value: "contentType" },
        { text: "", value: "startDate" },
        { text: "", value: "endDate" },
        { text: "", value: "totalCount"},
        {text: "", value: "clickOrigin" },
        { text: "", value: "displaySection"},
        {text: "", value: "status"},
        { text: "", value: "actions", align: "end" },
      ],
      adverts:[],
      notFound : "No advertisement",
      singleSelect: false,
      search:"",
      selectedRow: [],
      advert:{
        contentUrl:null
      },
      displayLocations:["WEB_TOP_LEFT","WEB_TOP_RIGHT","WEB_BOTTOM_LEFT","WEB_BOTTOM_RIGHT",
        "WEB_CENTER_LEFT","WEB_CENTER_RIGHT",
        "MOBILE_TOP","MOBILE_BOTTOM","MOBILE_CENTER"],
      contentSize:{},
      minDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      startMenu:false,
      endMenu:false,
      loading:false,
      skeletalLoading:false,
      showAddAdvert:false,
    }
  },
  computed:{
    filterMatchedAdverts() {
      return this.adverts.filter((advert) => {
        return !this.search || (advert.trackingId.toLowerCase().includes(this.search.toLowerCase()));
      })
    },
  },
  watch:{
    advert:{
      handler(val){
        if (val.displaySection){
          switch (val.displaySection) {
           case "WEB_TOP_LEFT":
             this.contentSize.height = "90px"
             this.contentSize.width = "728px"
                  break;
           case "WEB_BOTTOM_RIGHT":
             this.contentSize.height = "90px"
             this.contentSize.width = "728px"
             break;
            case "WEB_BOTTOM_LEFT":
              this.contentSize.height = "90px"
              this.contentSize.width = "728px"
              break;
            case "WEB_TOP_RIGHT":
              this.contentSize.height = "90px"
              this.contentSize.width = "728px"
              break;
           case "WEB_CENTER_LEFT":
             this.contentSize.height = "600px"
             this.contentSize.width = "160px"
             break;
            case "WEB_CENTER_RIGHT":
              this.contentSize.height = "600px"
              this.contentSize.width = "160px"
              break;
           case "MOBILE_TOP":
             this.contentSize.height = "50px"
             this.contentSize.width = "100%"
             break;
           case "MOBILE_BOTTOM":
             this.contentSize.height = "50px"
             this.contentSize.width = "100%"
             break;
           case "MOBILE_CENTER":
             this.contentSize.height = "250px"
             this.contentSize.width = "300px"
             break;
            default:
              this.contentSize.height = "20px"
              this.contentSize.width = "20px"
          }
        }
      },
      immediate:true,
      deep:true
    }
  },
  methods:{
    handleShowAddAdvert(){
      this.showAddAdvert = !this.showAddAdvert
    },
    async addNewAdvert() {
      this.loading = true
      let newData = {
        file: this.advert.contentUrl,
        upload_preset: process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
      }
      const response = await uploadToCloudinary(newData)
      this.advert.contentUrl = response.data.url
      const parts = this.advert.contentUrl.split('.');
      this.advert.contentType = parts[parts.length - 1].toUpperCase();
      if (this.advert.landingUrl.toLowerCase().startsWith("www.")){
        this.advert.landingUrl.replace("www.","https://")
      }
      if (this.advert.landingUrl.toLowerCase().startsWith("http://")){
        this.advert.landingUrl.replace("http://","https://")
      }
      if (!this.advert.landingUrl.toLowerCase().startsWith("https://")){
        this.advert.landingUrl = "https://"+this.advert.landingUrl
      }
      addNewAdvert(this.advert).then(res =>{
        this.$displaySnackbar({
          message: res.data.detail,
          success: true,
        });
        this.loading = false
        this.showAddAdvert = false
        this.getAdverts()
        this.advert = {}
      }).catch(err =>{
        this.loading = false
        console.log(err)
        this.$displaySnackbar({
          message: err.message,
          success: false,
        });
      }).finally(()=>
          this.loading = false)
    },
    async getAdverts(){
      this.skeletalLoading = true
      let data ={}
      data.page = 0
      data.pageSize = 100
     await getAllAdverts(data).then(res =>{
        this.adverts = res.data
       this.adverts.forEach(((advert,index) =>{
         advert.sno = index+1
       }))
        this.skeletalLoading = false
      }).catch(err =>{
        this.skeletalLoading = false
        console.log(err)
        this.$displaySnackbar({
          message: err.message,
          success: false,
        });
      }).finally(()=>
          this.skeletalLoading = false)
    },
    updateStatus(advert,status){
      let data ={}
      data.trackingId = advert.trackingId
      data.status = status
      updateAdvertStatus(data).then(() =>{
        this.getAdverts()
      }).catch(err =>{
        this.$displaySnackbar({
          message: err.message,
          success: false,
        });
      })
    },
    onUserImageClick() {
      this.$refs.userImage.click()
    },
    onUserImagePicked(event) {
      const files = event.target.files[0]
      try {
        const fileReader =  new FileReader()
        fileReader.addEventListener('load', async () => {
          this.advert.contentUrl = fileReader.result
        })
        fileReader.readAsDataURL(files)

      } catch (error) {
        console.log(error.response);
      }

    },
  },
  async created() {
   await this.getAdverts()
  }
}
</script>

<template>
<div class="tw-flex tw-w-full tw-flex-col">
  <div class="tw-flex tw-w-full tw-flex-col tw-items-center tw-pb-5" v-if="showAddAdvert">
    <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-my-5" style="height: 90px" :class="[advert.displaySection === 'WEB_TOP_LEFT'? 'tw-justify-start':'tw-justify-end']">
      <div v-if="advert.displaySection === 'WEB_TOP_LEFT'" class="picture-display" :style="{width:contentSize.width, height:contentSize.height}">
        <img :src="advert.contentUrl" alt="" v-if="advert.contentUrl" class="picture-display" :style="{width:contentSize.width, height:contentSize.height}">
      </div>
      <div v-if="advert.displaySection === 'WEB_TOP_RIGHT'" class="picture-display" :style="{width:contentSize.width, height:contentSize.height}">
        <img :src="advert.contentUrl" alt="" v-if="advert.contentUrl" class="picture-display" :style="{width:contentSize.width, height:contentSize.height}">
      </div>

    </div>
    <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between">
      <div class="tw-flex tw-w-2/12">
      <div v-if="advert.displaySection === 'WEB_CENTER_LEFT'" class="picture-display" :style="{width:contentSize.width, height:contentSize.height}">
        <img :src="advert.contentUrl" alt="" v-if="advert.contentUrl" class="picture-display" :style="{width:contentSize.width, height:contentSize.height}">
      </div>
      </div>
      <div class="tw-flex tw-w-8/12">
      <div class="add-advert-container tw-flex tw-flex-col tw-p-5">
        <div class="tw-flex tw-w-full tw-justify-between">
          <p class="table-header">New Advert</p>
          <v-btn class="table-header text-uppercase" @click="handleShowAddAdvert"><v-icon>mdi-close-circle</v-icon></v-btn>
        </div>

        <validation-observer ref="observer" v-slot="{ handleSubmit }" class="tw-flex tw-w-full tw-h-full">

          <form @submit.prevent="handleSubmit(addNewAdvert)" class="tw-flex tw-w-full tw-h-full tw-flex-col">
            <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-py-5 tw-items-center tw-justify-between">
              <validation-provider name="Advert Title" rules="required" v-slot="{ classes, errors }" class="text-field-width">
                <h3 class="status">Title/Purpose</h3>
                <v-text-field type="text" class="post-title-input" placeholder="Promotion 2024" solo hide-details v-model="advert.title"/>
                <span>{{errors[0]}}</span>
              </validation-provider>

              <validation-provider name="Amount Paid" rules="required" v-slot="{ classes, errors }" class="text-field-width">
                <h3 class="status">Amount Paid</h3>
                <v-text-field type="Number" class="post-title-input" placeholder="Promotion 2024" solo hide-details v-model="advert.amountPaid"/>
                <span>{{errors[0]}}</span>
              </validation-provider>
            </div>
            <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-py-5 tw-items-center tw-justify-between">
              <validation-provider name="Display Location" rules="required" v-slot="{ classes, errors }" class="text-field-width">
                <h3 class="status">Display Location</h3>
                <v-combobox :items="displayLocations" type="text" class="post-title-input" placeholder="Location on Page"
                            solo hide-details v-model="advert.displaySection"/>
                <span>{{errors[0]}}</span>
              </validation-provider>
              <div class="text-field-width tw-flex tw-flex-col">
                <h3 class="status" >Content: <span style="color: red">Size should be w:{{contentSize.width}} By h:{{contentSize.height}}</span></h3>
                <div class="upload-picture tw-cursor-pointer" @click="onUserImageClick" :style="{width:'300px', height:'250px'}">
                  <img :src="advert.contentUrl" alt="" v-if="advert.contentUrl" class="upload-picture" :style="{width:'300px', height:'250px'}">
                  <img src="@/assets/plus.svg" alt="" v-else >
                </div>
                <input type="file" ref="userImage" accept="image/png, image/jpeg, image/bmp, image/gif" style="display:none" @change="onUserImagePicked">
              </div>
            </div>
            <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-py-5 tw-items-center tw-justify-between">
              <validation-provider name="Start date" rules="required" v-slot="{ classes, errors }" class="text-field-width">
                <h3 class="status">Start Date</h3>
                <v-menu ref="startDateMenu" v-model="startMenu" :close-on-content-click="false"
                        :return-value.sync="advert.startDate" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="advert.startDate" solo prepend-inner-icon="mdi-calendar" readonly
                                  v-bind="attrs" v-on="on" placeholder="2022-01-01" hide-details color="#004aad"></v-text-field>
                  </template>
                  <v-date-picker v-model="advert.startDate" no-title scrollable :min="minDate" color="#004aad">
                    <v-spacer></v-spacer>
                    <v-btn text color="#004aad" @click="startMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="#004aad" @click="$refs.startDateMenu.save(advert.startDate)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <span >{{errors[0]}}</span>
              </validation-provider>

              <validation-provider name="End date" rules="required" v-slot="{ classes, errors }" class="text-field-width">
                <h3 class="status">End Date</h3>
                <v-menu ref="endDateMenu" v-model="endMenu" :close-on-content-click="false"
                        :return-value.sync="advert.endDate" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="advert.endDate" solo prepend-inner-icon="mdi-calendar" readonly
                                  v-bind="attrs" v-on="on" placeholder="2022-01-01" hide-details color="#004aad"></v-text-field>
                  </template>
                  <v-date-picker v-model="advert.endDate" no-title scrollable :min="advert.startDate" color="#004aad">
                    <v-spacer></v-spacer>
                    <v-btn text color="#004aad" @click="endMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="#004aad" @click="$refs.endDateMenu.save(advert.endDate)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <span >{{errors[0]}}</span>
              </validation-provider>
            </div>
            <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-py-5 tw-items-center tw-justify-between">
              <validation-provider name="Target Url" rules="required" v-slot="{ classes, errors }" class="text-field-width">
                <h3 class="status">Target Url </h3>
                <v-text-field type="url" class="post-title-input" placeholder="https://npod.ng/booking" solo hide-details v-model="advert.landingUrl"/>
                <span>{{errors[0]}}</span>
              </validation-provider>
              <div class="text-field-width">
                <base-button button-text="Submit" type="submit" width="100%" class="tw-mt-5" :loading="loading"></base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
      </div>
      <div class="tw-flex tw-w-2/12">
      <div v-if="advert.displaySection === 'WEB_CENTER_RIGHT'" class="picture-display" :style="{width:contentSize.width, height:contentSize.height}">
        <img :src="advert.contentUrl" alt="" v-if="advert.contentUrl" class="picture-display" :style="{width:contentSize.width, height:contentSize.height}">
      </div>
      </div>
    </div>
    <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-my-5" style="height: 90px" :class="[advert.displaySection === 'WEB_BOTTOM_LEFT'? 'tw-justify-start':'tw-justify-end']">
      <div v-if="advert.displaySection === 'WEB_BOTTOM_LEFT'" class="picture-display" :style="{width:contentSize.width, height:contentSize.height}">
        <img :src="advert.contentUrl" alt="" v-if="advert.contentUrl" class="picture-display" :style="{width:contentSize.width, height:contentSize.height}">
      </div>
      <div v-if="advert.displaySection === 'WEB_BOTTOM_RIGHT'" class="picture-display" :style="{width:contentSize.width, height:contentSize.height}">
        <img :src="advert.contentUrl" alt="" v-if="advert.contentUrl" class="picture-display" :style="{width:contentSize.width, height:contentSize.height}">
      </div>

    </div>
  </div>
  <div class="table-container-div tw-p-5">
    <div
        class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
      <div class="tw-flex tw-items-center">
        <p class="table-header tw-mr-10"
           style="color: #004AAD">
          Adverts</p>
        <v-btn class="table-header text-uppercase" @click="handleShowAddAdvert"><v-icon>mdi-plus-circle</v-icon></v-btn>
      </div>

      <div
          class="
                tw-flex tw-flex-row tw-justify-end
                lg:tw-justify-center
                tw-items-center
              "
      >
        <div
            class="mr-2"
            style="
                  border-radius: 10px;
                  border: 1px solid rgba(79, 79, 79, 0.08);
                "
        >
          <v-text-field
              placeholder="Enter Tracking Id"
              class="p-0"
              solo
              flat
              hide-details
              v-model="search"
          >
            <template #prepend>
              <v-icon color="#004AAD" class="ml-5">
                mdi-magnify
              </v-icon>
            </template>
            <template #append>
              <v-icon color="#004AAD">
                mdi-filter-variant
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </div>
    </div>
    <v-skeleton-loader elevation="0" type="image, list-item-three-line" v-if="skeletalLoading" :loading="skeletalLoading"></v-skeleton-loader>
    <v-data-table v-else
        :headers="headers"
        v-model="selectedRow"
        :mobile-breakpoint="0"
        item-key="id"
        :single-select="singleSelect"
        height="available"
        :hide-default-footer="adverts.length <= 10"
        :items="filterMatchedAdverts"
        :no-data-text="notFound"
        class="mt-4 mx-1 table"
        checkbox-color="#004AAD"
    >
      <template #header.sno>
        <span class="status">S/N</span>
      </template>
      <template v-slot:[`item.sno`]="{ item }">
        <span class="td-style">{{ item.sno }}</span>
      </template>
      <template #header.advertiser>
        <span class="status">Advertiser</span>
      </template>
      <template v-slot:[`item.advertiser`]="{ item }">
        <span class="td-style">{{ item.advertiser }}</span>
      </template>
      <template #header.trackingId>
        <span class="status">Tracking ID</span>
      </template>
      <template v-slot:[`item.trackingId`]="{ item }">
        <span class="td-style">{{ item.trackingId }}</span>
      </template>
      <template #header.title>
        <span class="status">Title/Purpose</span>
      </template>
      <template v-slot:[`item.title`]="{ item }">
        <span class="td-style">{{ item.title }}</span>
      </template>
      <template #header.amountPaid>
        <span class="status">Amount Paid (&#x20A6;)</span>
      </template>
      <template v-slot:[`item.amountPaid`]="{ item }">
        <span class="td-style">{{ item.amountPaid }}</span>
      </template>
      <template #header.content>
        <span class="status">Content</span>
      </template>
      <template v-slot:[`item.content`]="{ item }">
        <span class="td-style" >
          <img v-if="item.contentUrl" :src="item.contentUrl" class="table-image" alt="content"/></span>
      </template>
      <template #header.contentType>
        <span class="status">Content Type</span>
      </template>
      <template v-slot:[`item.contentType`]="{ item }">
        <span class="td-style">{{ item.contentType }}</span>
      </template>
      <template #header.startDate>
        <span class="status">Start Date</span>
      </template>
      <template v-slot:[`item.startDate`]="{ item }">
        <span class="td-style">{{ item.startDate }}</span>
      </template>
      <template #header.endDate>
        <span class="status">End Date</span>
      </template>
      <template v-slot:[`item.endDate`]="{ item }">
        <span class="td-style">{{ item.endDate }}</span>
      </template>
      <template #header.totalCount>
        <span class="status">Total Clicks</span>
      </template>
      <template v-slot:[`item.totalCount`]="{ item }">
        <span class="td-style">{{ item.totalCount }}</span>
      </template>
      <template #header.clickOrigin>
        <span class="status">Total Devices/Users</span>
      </template>
      <template v-slot:[`item.clickOrigin`]="{ item }">
        <span class="td-style">{{ item.totalClickOrigin }}</span>
      </template>
      <template #header.displaySection>
        <span class="status">Display Section</span>
      </template>
      <template v-slot:[`item.displaySection`]="{ item }">
        <span class="td-style">{{ item.displaySection }}</span>
      </template>
      <template #header.status>
        <span class="status">Status</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span class="td-style">{{ item.status }}</span>
      </template>
      <template v-slot:[`item.actions`] ="{item}">
        <v-menu bottom  left>
          <template v-slot:activator="{ on, attrs }">
            <td class="d-flex justify-center">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </td>
          </template>
          <v-list>
            <v-list-item class="" v-if="item.status !== 'ACTIVE'" @click="updateStatus(item,'ACTIVE')">
              <v-list-item-title
                  style="color: black"
                  class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                Activate
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="" v-if="item.status !== 'INACTIVE'" @click="updateStatus(item,'INACTIVE')">
              <v-list-item-title
                  style="color: black"
                  class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                  Deactivate
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="" v-if="item.status !== 'SUSPENDED'" @click="updateStatus(item,'SUSPENDED')">
              <v-list-item-title
                  style="color: black"
                  class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                Suspend
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="" >
              <v-list-item-title
                  style="color: black"
                  class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                Edit
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</div>
</template>

<style scoped lang="scss">
.table-header {
  font-family: "Inter",sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: rgba(0, 74, 173, 1);
  text-transform: capitalize!important;
}
.table-container-div {
  box-shadow: 0 4px 40px 0 #4f4f4f05;
  border-radius: 10px;
  background-color: white;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter",sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0;
      text-align: left;
      color: #004aad;
    }
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 0;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 0 0 0;
}

::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 2px;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}

.add-advert-container{
  width: 60rem;
  min-height: 30rem;
  height: max-content;
  background-color: white;
  border-radius: 15px;
}
.text-field-width{
  width: 48% !important;
  @media screen and (max-width: 768px){
    width: 100%;
  }
}
.text-field-width-small{
  width: 30% !important;
  @media screen and (max-width: 768px){
    width: 100%;
  }
}
.span-error{
  font-family: "Raleway",sans-serif;
  color: red;
  font-size: 14px;
  margin-top: 5px;
  //margin-left: 20px;
}
.upload-picture{
  display: flex;
  border: 1px solid rgba(79,79,79,0.5);
  align-items: center;
  justify-content: center;
  object-fit: contain;
  border-radius: 20px;
  @media screen and (max-width: 768px){
    width: 100%;
  }
}

.picture-display{
  display: flex;
  border: 1px solid rgba(79,79,79,0.5);
  align-items: center;
  justify-content: center;
  object-fit: contain;
  border-radius: 5px;
}
.table-image{
  width: max-content;
  height: max-content;
  object-fit: contain;
}
</style>